import logo from "../assets/notelogo.png";
import kazyoga from "../assets/kazyoga.png";
import heart from "../assets/smallheart.png";

const exportedImages = {
  logo,
  kazyoga,
  heart,
};

export default exportedImages;
